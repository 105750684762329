<template>
  <div class="column">
    <div class="top_back">
      <img
        class="back"
        src="../../../assets/img/home/home/PES/icon_pes_back.png"
        @click="back"
      />
      <div class="title">标准体质测试</div>
    </div>
    <div class="month_lay">
      <div class="w1000">
        <div class="swip-item">
          <div
            v-for="(item, index) of monthList"
            :key="index"
            class="row_normal"
          >
            <div class="month_normal text_center">{{ item.name }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="row_normal text_center">
      <div class="content">
        <div class="row_normal">
          <div class="flex"></div>
          <div class="pes-select-3 select-item">
            <el-select
              v-model="valueClass"
              placeholder="选择班级"
              @change="bindStu"
            >
              <el-option
                v-for="item in options3"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="pl_input">批量导入</div>
        </div>
        <el-table :data="tableData" style="width: 100%;margin-top: 30px;">
          <el-table-column type="index" label="编号"> </el-table-column>
          <el-table-column prop="sheng" label="姓名"> </el-table-column>
          <el-table-column prop="diqu" label="性别"> </el-table-column>
          <el-table-column prop="schoolname" label="年龄"> </el-table-column>
          <el-table-column prop="schoolname" label="身高/cm"> </el-table-column>
          <el-table-column prop="schoolname" label="体重/kg"> </el-table-column>
          <el-table-column prop="schoolname" label="左右视力">
          </el-table-column>
          <el-table-column prop="schoolname" label="臂展/cm"> </el-table-column>
          <el-table-column prop="schoolname" label="胸围/cm"> </el-table-column>
          <el-table-column prop="schoolname" label="体前屈/cm">
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div class="h100"></div>
  </div>
</template>
<script>
export default {
  name: "normalTzTest",
  components: {},
  data() {
    return {
      monthList: [
        { name: "1月", year: "2023" },
        { name: "2月", year: "2023" },
        { name: "3月", year: "2023" },
        { name: "4月", year: "2023" },
        { name: "1月", year: "2023" },
        { name: "2月", year: "2023" },
        { name: "3月", year: "2023" },
        { name: "4月", year: "2023" },
        { name: "1月", year: "2023" },
        { name: "2月", year: "2023" },
      ],
      valueClass: "",
      options3: [
        {
          label: "中班",
          value: 1,
        },
        {
          label: "小班",
          value: 2,
        },
        {
          label: "大班",
          value: 3,
        },
      ],
      tableData: [],
    };
  },

  methods: {
    back() {
      this.$router.go(-1);
    },
    bindStu(val) {
      console.log(val);
    },
  },
  mounted() {},
  computed: {},
};
</script>
  <style lang="scss" scoped>
.column {
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #e7e7e7;
}
.top_back {
  position: relative;
  height: 42px;
  background: #333333;
  .back {
    position: absolute;
    top: 13px;
    left: 140px;
    width: 42px;
    height: 15px;
    cursor: pointer;
  }
  .title {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 32px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
  }
}
.row_normal {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.month_lay {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: #333333;
}
.w1000 {
  width: 1200px;
  height: 40px;
  overflow-x: scroll;
}
.swip-item {
  height: 40px;
  display: flex;
  flex-flow: row nowrap;
}
.month_normal {
  width: 70px;
  height: 30px;
  font-size: 22px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  cursor: pointer;
}
.text_center {
  display: flex;
  align-items: center;
  justify-content: center;
}
::-webkit-scrollbar {
  width: 0 !important;
}
::-webkit-scrollbar {
  width: 0 !important;
  height: 0;
}
.flex {
  flex: 1;
}
.content {
  width: 1200px;
  background: #fff;
  margin-top: 40px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding-top: 40px;
  padding-bottom: 50px;
}
.pes-select {
  display: flex;
  .select-item {
    width: 130px;
    height: 38px;
    margin-right: 32px;
    /deep/ .el-input__inner {
      font-size: 18px;
    }
  }
}
.pl_input {
  font-size: 22px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #b02424;
  margin-left: 20px;
  margin-right: 30px;
}
.h100{
    height: 100px;
}
</style>